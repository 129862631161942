<template>
  <section class="HomeBanner" v-if="$locale">
    <div class="HomeBanner-data">
      <div class="HomeBanner-image">
        <img :src="$locale.object['home-info-banner'].image" :alt="$locale.object['home-info-banner'].title" id="renderImg" ref="renderImg" />
      </div>
      <div class="HomeBanner-info">
        <h2 class="HomeBanner-title special-title">{{ $locale.object["home-info-banner"].title }}</h2>
        <p class="HomeBanner-description">{{ $locale.object["home-info-banner"].desc }}</p>
        <router-link class="HomeBanner-button" to="/contact">{{ $locale.object["home-info-banner"].button }}</router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    load(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible ? target.classList.add("active") : target.classList.remove("active");
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.load, { threshold: 0.25 });
    observer.observe(this.$refs.renderImg);
  },
};
</script>
<style lang="scss">
.HomeBanner {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0) 5.21%, #444444 71.87%);
  color: white;
  font-family: Blinker;
  padding: 100px 0 50px 0;
  color: white;
  font-family: $sec_font;
  padding: 35px 0 50px 0;
  margin-bottom: 50px;
  &-image {
    width: 100%;
    img {
      width: 100%;
      transform: scale(0.7) translateX(-50%);
      opacity: 0;
      transition: 2s ease-in-out all;
    }
    .active {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
  &.active {
    width: 100%;
    img {
      width: 100%;
      opacity: 1;
    }
  }
  &-info {
    padding: 0px 20px;
  }
  &-title {
    margin-top: 26px;
    margin-bottom: 10px;
  }
  &-description {
    font-family: $font_text;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 300;
  }
  &-button {
    background: $shark;
    padding: 8px 28px;
    border-radius: 14px;
    color: $mercury;
    font-weight: 600;
    &:hover {
      background: $mercury;
      border: 1px solid $shark;
      color: $shark;
    }
  }
  @media (min-width: $tablet_width) {
    &-info {
      width: 53%;
      margin-left: auto;
      margin-top: -90px;
    }
    &-title,
    &-description {
      margin-bottom: 20px;
    }
  }
  @media (min-width: $desktop_width) {
    padding-bottom: 0;
    &-data {
      max-width: $content_width;
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }
    &-info {
      width: 60%;
      margin-right: 40px;
      padding-left: 40px;
    }
    &-description {
      line-height: 24px;
    }
  }
  @media (min-width: $desktop_width) {
    padding-top: 100px;
    &-data {
      margin: 0 auto;
    }
    &-title {
      font-size: 24px;
    }
    &-image {
      align-self: flex-end;
      img {
        width: 110%;
        margin-bottom: -4px;
      }
    }
  }
}
</style>
