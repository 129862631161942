<template>
  <div class="homeSlide" v-if="item">
    <div class="homeSlide__mask"></div>
    <div class="main">
      <div class="homeSlide__text">
        <p class="homeSlide__title special-title">{{ item.title }}</p>
        <span class="homeSlide__description">{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: null,
  },
};
</script>

<style lang="scss">
.homeSlide {
  position: relative;
  min-height: 500px;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  background-position: center;
  &__mask {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  &__text {
    position: relative;
    z-index: 100;
    color: white;
    padding: 0 20px 55px 20px;
    display: flex;
    flex-direction: column;
  }
  &__title {
    padding-bottom: 8px;
    font-family: $sec_font;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  &__description {
    font-family: $font_text;
    font-size: 17px;
    line-height: 16px;
    position: relative;
  }
  @media (min-width: $tablet_width) {
    &__text {
      text-align: justify;
      padding: 0 calc(100vw - 450px) 55px 45px;
    }
    &__title {
      font-size: 24px;
      line-height: 32px;
    }
    &__description {
      min-width: 524px;
    }
  }
  @media (min-width: $desktop_width) {
    min-height: 80vh;
    &__text {
      padding: 0 calc(100vw - 555px) 80px 45px;
    }
    &__title {
      font-size: 36px;
      line-height: 48px;
      width: 0px;
      overflow: hidden;
      white-space: nowrap;
      animation: typing 3s steps(38) infinite, blink 1s steps(20) infinite;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
    }
    &__mask {
      background: linear-gradient(180deg, rgba(222, 213, 213, 0) 0%, #000000 100%);
    }
  }
  @media (min-width: $tv_width) {
    &__text {
      padding: 0 calc(100vw - 555px) 80px 15px;
    }
  }
}
</style>
