<template>
  <main class="main-container" v-if="$locale['main-banner-title']">
    <MainBanner />
    <HomeWork />
    <HomeCarousel />
    <HomeText />
    <HomeProjects />
    <HomeInfoBanner />
    <!-- <HomeCards /> -->
    <HomeCardsSlider />
  </main>
  <Spinner v-else />
</template>

<script>
import MainBanner from "./MainBanner.vue";
import HomeWork from "./HomeWorks.vue";
import HomeCardsSlider from "./HomeCardsSlider.vue";
import HomeText from "./HomeText.vue";
import HomeCarousel from "./HomeCarousel.vue";
import HomeCards from "./HomeCards.vue";
import HomeProjects from "./HomeProjects.vue";
import HomeInfoBanner from "./HomeInfoBanner.vue";

export default {
  components: { MainBanner, HomeWork, HomeCarousel, HomeText, HomeCards, HomeProjects, HomeInfoBanner, HomeCardsSlider },
  async beforeMount() {
    if (!this.$locale["main-banner-title"]) await this.$store.dispatch("updateLocal");
  },
};
</script>

<style lang="scss">
.main-container {
  position: relative;
}
.circular {
  width: 200px;
  height: 200px;
}
</style>
