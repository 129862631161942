<template>
  <div class="homeCarousel" v-if="$locale">
    <VueSlickCarousel class="homeCarousel__slide" v-bind="settings" v-if="$locale['object']['home-carousel']">
      <div v-for="(item, index) in items" :key="index" class="homecarousel__info">
        <HomeSlide class="homeCarousel__slide mobile" :item="item" :style="`background-image: url(${item.img})`" />
        <HomeSlide class="homeCarousel__slide desktop" :item="item" :style="`background-image: url(${item.imgDesktop})`" />
      </div>
    </VueSlickCarousel>
    <!-- <div class="homeCarousel__innovation">
      <h1 v-for="index in 11" :key="index" :style="'--i:' + index" class="homeCarousel__innovation--title">Innovation</h1>
    </div> -->
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import HomeSlide from "./HomeSlide.vue";
export default {
  components: {
    VueSlickCarousel,
    HomeSlide,
  },
  data() {
    return {
      settings: {
        dots: true,
      },
    };
  },
  computed: {
    items() {
      return Object.values(this.$locale["object"]["home-carousel"]);
    },
  },
};
</script>

<style lang="scss">
.homeCarousel {
  // position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 500px;
  // // background: #e5e5e5;
  // background: linear-gradient(90deg, #00000094 21%, rgba(137, 137, 236, 0) 98%);
  // &__innovation {
  //   transform-style: preserve-3d;
  //   animation: animate 30s linear infinite;
  //   @keyframes animate {
  //     0% {
  //       transform: perspective(1000px) rotateX(0deg) rotate(0deg);
  //     }
  //     100% {
  //       transform: perspective(1000px) rotateX(100deg) rotate(20deg);
  //     }
  //   }
  //   &--title {
  //     display: flex;
  //     position: absolute;
  //     color: rgb(240, 240, 240);
  //     font-size: 140px;
  //     font-weight: 800;
  //     transform: translate(-50%, -50%) rotateX(calc(var(--i) * 36deg)) translateZ(150px);
  //     background-color: rgba(248, 248, 248, 0.25);
  //     text-transform: uppercase;
  //     text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  //     line-height: 16px;
  //     & i:first-child:after {
  //       content: "L";
  //       color: #ccc;
  //       text-transform: lowercase;
  //     }
  //     & i:last-child {
  //       color: #f00;
  //       filter: blur(2px);
  //       text-shadow: -4px 0 2px #000, 9px 0 20px #f00, 9px 0 24px #f00, 9px 0 28px #f00;
  //     }
  //   }
  // }
  &__slide {
    .desktop {
      display: none;
    }
  }
  &__info {
    background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 21%, hsla(240, 92%, 76%, 0) 98%);
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  .slick-prev {
    left: 40px;
    &:before {
      font-size: 25px;
    }
  }
  .slick-next {
    right: 40px;
    &:before {
      font-size: 25px;
    }
  }
  .slick-dots {
    width: 100%;
    bottom: $mpadding;
    li button:before {
      font-size: 15px;
      color: white;
      z-index: 2;
    }
    li.slick-active button:before {
      color: white;
    }
  }
  @media (min-width: $pocket_width) {
    &__slide {
      .desktop {
        display: flex;
      }
      .mobile {
        display: none;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .slick-dots {
      width: auto;
      right: 30px;
      bottom: 50px;
      li button:before {
        font-size: 9px;
      }
    }
  }
}
</style>
