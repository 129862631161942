<template>
  <div class="home-works" v-if="$locale">
    <div class="home-works-content main">
      <div class="home-works-header">
        <p class="home-works-title" dtitle>{{ $locale["home-works-title"] }}</p>
        <p class="home-works-subtitle">{{ $locale["home-works-subtitle"] }}</p>
      </div>
      <div class="home-works-cards">
        <VueSlickCarousel v-bind="settings" class="home-works-carrousel">
          <HomeWorkCard v-for="(card, index) in cards" :key="`wk-${index}`" :icon="card.icon" :title="card.title" :desc="card.desc" />
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import HomeWorkCard from "./HomeWorkCard.vue";
export default {
  components: { VueSlickCarousel, HomeWorkCard },
  data: function () {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 10000,
            settings: "unslick",
          },
          {
            breakpoint: 1023,
            settings: {
              arrows: true,
              infinite: true,
              initialSlide: -0.2,
              slidesToShow: 2.2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              infinite: true,
              initialSlide: -0.2,
              slidesToShow: 1.2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    cards() {
      return this.$store.getters.locale["object"]["works-cards"];
    },
  },
};
</script>

<style lang="scss">
.home-works {
  position: relative;
  padding: $mpadding * 3 0 $mpadding * 3 0;
  height: max-content;
  &-header {
    text-align: center;
    padding: $mpadding;
  }
  &-title {
    font-family: $sec_font;
    font-weight: bold;
  }
  &-subtitle {
    margin: $mpadding 0 0 0;
    font-family: $font_text;
    font-size: 17px;
  }
  &-cards {
    position: relative;
    margin: $mpadding * 2 0 0 0;
  }
  &-carrousel:not(.slick-slider) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .works-card {
      margin: $mpadding $mpadding $mpadding $mpadding;
    }
  }
  &-content {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: -1;
    }
    &:before {
      left: 0;
      bottom: 0;
      background: url("https://onegroup.s3.amazonaws.com/website/components/HomeWorks/Group+46.svg") no-repeat;
      background-position: left -100px top -110px;
      background-size: 260px;
    }
    &:after {
      display: none;
      right: 0;
      background: url("https://onegroup.s3.amazonaws.com/website/components/HomeWorks/Group+47.svg") no-repeat;
    }
  }
  &-carrousel {
    .slick-prev,
    .slick-next {
      z-index: 1;
      height: calc(100% - #{$mpadding});
      width: 54px;
      position: absolute;
      top: 50%;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 44px;
      height: 44px;
      line-height: 44px;
      position: absolute;
      top: calc(50% - 22px);
      border-radius: 50%;
      font-family: fontawesome;
      text-align: center;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0;
    }
    .slick-prev {
      left: 0;
      padding-left: $mpadding * 2;
      background: linear-gradient(to right, #cdcdcd85 70%, #fefefe00);
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      &::before {
        right: 0;
      }
    }
    .slick-next {
      right: 0;
      padding-right: $mpadding * 2;
      background: linear-gradient(to left, #cdcdcd85 70%, #fefefe00);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      &::before {
        left: 0;
      }
    }
    .slick-prev::before {
      content: "\f053";
    }
    .slick-next::before {
      content: "\f054";
    }
  }
  @media (min-width: $tablet_width) {
    &-content {
      &:before {
        background-position: left -101% top -32px;
        background-size: 360px;
      }
      &:after {
        display: block;
        background-position: right -65% top -295px;
        background-size: 360px;
      }
    }
  }
  @media (min-width: $desktop_width) {
    &-content {
      &:before {
        background-position: left 20px top -70px;
        background-size: 360px;
      }
      &:after {
        background-position: right 20px top -255px;
        background-size: 360px;
      }
    }
  }
}
</style>
