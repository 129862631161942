<template>
  <div class="homeCard" v-if="$locale">
    <div class="main">
      <div class="homeCard__squares"><img :src="$locale['object']['boxes-image'].image" alt="Boxes" /></div>
      <div class="homeCard__container" v-if="$locale['object']['home-cards']">
        <HomeWorkCard
          v-for="(item, index) in $locale['object']['home-cards']"
          :key="index"
          class="homeCard__item"
          :icon="item.icon"
          :title="item.title"
          :desc="item.describe"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HomeWorkCard from "./HomeWorkCard.vue";
export default {
  components: { HomeWorkCard },
};
</script>

<style lang="scss">
.homeCard {
  padding: 45px 15px 45px 15px;
  &__squares {
    position: absolute;
    z-index: -1;
    margin-top: -60%;
    width: 366px;
    left: -14%;
    img {
      width: 100%;
    }
  }
  &__container {
    display: grid;
    row-gap: 10px;
  }
  @media (min-width: $tablet_width) {
    &__squares {
      left: -8%;
      margin-top: -31%;
    }

    &__container {
      grid-template:
        "card1 card2 card2"
        "card1 card3 card4";
      row-gap: 30px;
    }
    &__item {
      .works-card {
        height: 100%;
      }
      &:nth-child(1) {
        grid-area: card1;
      }
      &:nth-child(2) {
        grid-area: card2;
      }
      &:nth-child(3) {
        grid-area: card3;
      }
      &:nth-child(4) {
        grid-area: card4;
      }
    }
  }
  @media (min-width: $desktop_width) {
    &__squares {
      width: 550px;
      left: -100px;
      margin-top: -400px;
    }
    padding-left: 50px;
    padding-right: 50px;
  }
}
</style>
