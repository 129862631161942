<template>
  <div class="homeText" id="changeLight" ref="changeLight">
    <div class="main" v-if="$locale">
      <div class="homeText__content">
        <div class="homeText__text">
          {{ $locale["home-textBanner"] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    load(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible ? target.classList.add("active") : target.classList.remove("active");
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.load, { threshold: 0.6 });
    observer.observe(this.$refs.changeLight);
  },
};
</script>

<style lang="scss">
.homeText {
  height: 300px;
  position: relative;
  background: linear-gradient(360deg, rgb(7, 6, 6) 21%, hsla(240, 92%, 76%, 0) 600%);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.75s;
    background-image: url("../../assets/images/bg_luces.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 84%;
    opacity: 0;
  }

  .homeText__text {
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.85s;
    transform: scale(0.5);
    opacity: 0;
  }

  &.active::before,
  &.active .homeText__text {
    opacity: 1;
    transform: scale(1);
  }

  .main {
    position: relative;
    height: 100%;
    z-index: 1;
  }
  &__content {
    color: white;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 15px 60px 80px 60px;
    font-family: $sec_font;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.61);
    word-spacing: 40px;
    margin-top: 12%;
  }
  @media (min-width: 375px) {
    &::before {
      content: "";
      background-position: 85%;
    }
    &__text {
      text-align: center;
    }
  }
  @media (min-width: $mobile_width) {
    padding: 0 20px;
    &::before {
      content: "";
      background-position: 86%;
    }
    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &__text {
      margin-top: 12%;
      text-align: center;
    }
  }
  @media (min-width: $tablet_width) {
    &::before {
      content: "";
      background-position: 75%;
    }
    &__text {
      word-spacing: 35px;
      padding-left: 20px;
      margin-top: 12%;
    }
  }
  @media (min-width: $desktop_width) {
    height: 600px;
    &::before {
      content: "";
      background-position: 65%;
    }
    &__text {
      max-width: 970px;
      font-size: 56px;
      margin-top: 3%;
      word-spacing: 25px;
      padding-left: 1px;
      margin-left: 2%;
      margin-top: 17%;
    }
  }
  @media (min-width: $tv_width) {
    height: 650px;
    &::before {
      content: "";
      background-position: 80%;
    }
    &__text {
      word-spacing: 30px;
      margin-left: 9%;
      margin-top: 15%;
    }
  }
  // @media (min-width: $tvPlus_width) {
  //   background-position-y: 80%;
  //   // height: 25vh;
  //   height: 500px;
  // }
}
</style>
