<template>
  <section class="projects" v-if="$locale && $locale.words">
    <h2 class="projects-title">{{ $locale["projects-title"] }}</h2>
    <div class="projects-container">
      <div v-for="(project, idx) in $locale.projects" :key="project._id" :class="['project', idx % 2 !== 0 ? 'project-left' : 'project-right']">
        <img class="project-image" :src="project.image" :alt="project.name" />
        <div class="project-info">
          <h3 class="project-title special-title">{{ project.name }}</h3>
          <p class="project-client">{{ $locale.words.client }}: {{ project.client }}</p>
          <p class="project-description">{{ project.description }}</p>
        </div>
        <div v-if="idx % 2 !== 0" class="project-block"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss">
.projects {
  font-family: $sec_font;
  margin-bottom: 58px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 65px;
    height: 65px;
    border: 1px solid #666666;
    transform: rotate(-45deg);
    z-index: -1;
    top: -100px;
    right: -10px;
    filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  }
  &-container {
    display: grid;
    gap: 78px;
  }
  &-title {
    margin: 78px 0 32px 0;
    margin-top: 78px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .project {
    display: grid;
    &-image {
      justify-self: end;
      width: 90%;
      border-radius: 12px 0px 0px 12px;
      filter: drop-shadow(6px 6px 8px rgba(102, 102, 102, 0.3));
    }
    &-info {
      background: url("https://onegroup.s3.amazonaws.com/website/components/Projects/description-bg.png");
      background-size: cover;
      width: 90%;
      color: white;
      border-radius: 0px 12px 12px 0px;
      padding: 28px 20px;
      margin-top: -28px;
      z-index: 1;
    }
    &-title {
      margin: 7.5px 0 8px 0;
    }
    &-client {
      font-weight: 600;
      color: $mercury;
      font-size: 12px;
      margin-bottom: 8px;
    }
    &-description {
      font-family: $font_text;
      font-size: 17px;
    }

    &-block {
      height: 480px;
      width: 100%;
      background: linear-gradient(180deg, #444444 72.21%, rgba(68, 68, 68, 0.78) 72.21%);
      margin-top: -720px;
    }
  }
}
@media (min-width: $tablet_width) {
  .projects {
    .project {
      &-image {
        border-radius: 12px;
        width: 82%;
      }
      &-info {
        width: 45%;
        margin-top: -130px;
        padding: 46px 20px;
      }
      &-block {
        height: 450px;
        margin-top: -474px;
      }
      &-right {
        .project {
          &-image {
            margin-right: 28px;
          }
        }
      }
      &-left {
        .project {
          &-image {
            margin-left: 28px;
            justify-self: start;
          }
          &-info {
            border-radius: 12px 0px 0px 12px;
            justify-self: end;
          }
        }
      }
    }
  }
}
@media (min-width: $desktop_width) {
  .projects {
    &-title {
      font-size: 36px;
      margin-bottom: 48px;
    }
  }
}

@media (min-width: $tv_width) {
  .projects {
    &::before {
      width: 100px;
      height: 100px;
      left: calc(50% + 500px);
    }
    &-title {
      font-size: 36px;
      margin-bottom: 50px;
    }
    &-container {
      gap: 120px;
    }
    .project {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: $content_width;
      margin: 0 auto;
      &-image {
        width: 60%;
      }
      &-info {
        height: auto;
        width: 35%;
        margin: 0;
        padding: 55px 46px;
        border-radius: 12px;
      }
      &-title {
        font-size: 24px;
      }
      &-description {
        font-size: 16px;
      }
      &-block {
        left: -100vw;
        position: absolute;
        z-index: -1;
        bottom: -23px;
        margin-top: 0;
        height: 736px;

        width: 200vw;
      }
      &-right {
        flex-direction: row-reverse;
        .project-info {
          border-radius: 12px;
        }
        .project-image {
          margin-right: 0;
        }
      }
      &-left {
        .project-info {
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
