<template>
  <section class="home-cardsSlider" v-if="$locale">
    <div class="home-cardsSlider-content main">
      <div class="home-cardsSlider-header">
        <h2 class="home-cardsSlider-title" dtitle>
          {{ $locale["home-cardsSlider-title"] }}
        </h2>
      </div>
      <div class="home-cardsSlider-cards">
        <VueSlickCarousel v-bind="settings" class="home-cardsSlider-carrousel">
          <div v-for="(card, idx) in cards" :key="`sc-${idx}`" class="sliderCard" @click="ver(idx.substr(-1))">
            <router-link to="/services">
              <div class="sliderCard-info">
                <h3 class="sliderCard-title special-title">{{ card.title }}</h3>
                <h4 class="sliderCard-subtitle">{{ card.subtitle }}</h4>
                <p class="sliderCard-description">{{ card.desc }}</p>
              </div>
              <img class="sliderCard-background" :src="card.image" alt="background card" />
            </router-link>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: { VueSlickCarousel },
  data: function () {
    return {
      settings: {
        focusOnSelect: true,
        arrows: false,
        dots: false,
        infinite: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 10000,
            settings: {
              arrows: true,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1025,
            settings: {
              arrows: true,
              infinite: true,
              initialSlide: -0.5,
              slidesToShow: 2.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              infinite: true,
              initialSlide: -0.2,
              slidesToShow: 1.2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    cards() {
      return this.$locale["object"]["slider-cards"];
    },
  },
};
</script>

<style lang="scss">
.home-cardsSlider {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0.44) 2.6%, #666666 83.33%);
  @media (min-width: $desktop_width) {
    margin-top: 100px;
  }
  &-title {
    text-align: center;
  }
  &-cards {
    padding: 48px 0 58px 0;
    @media (min-width: $tablet_width) {
      padding-bottom: 78px;
    }
    @media (min-width: $desktop_width) {
      padding-bottom: 85px;
    }
    .slick-arrow {
      z-index: 1;
      width: 32px;
      height: 32px;
      &::before {
        font-size: 32px;
      }
    }
    .slick-prev {
      left: 5%;
      @media (min-width: $tv_width) {
        left: 2%;
      }
    }
    .slick-next {
      right: 5%;
      @media (min-width: $tv_width) {
        right: 2%;
      }
    }
  }
}
.sliderCard {
  background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 21%, hsla(240, 92%, 76%, 0) 98%);
  position: relative;
  height: 430px;
  max-width: calc(100% - 10px * 2);
  padding: 24px 16px;
  color: white;
  border-radius: 12px;

  &-subtitle {
    font-size: 12px;
    margin-bottom: 10px;
  }
  &-description {
    font-size: 14px;
  }
  &-info {
    background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 21%, hsla(240, 92%, 76%, 0) 98%);
    position: absolute;
    bottom: 24px;
    padding: 0 3px;
    cursor: pointer;
  }
  &-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
}
</style>
