<template>
  <div class="works-card-wrap">
    <router-link to="services">
      <div class="works-card">
        <div class="icon"><iconic :name="icon" /></div>
        <div class="title">{{ title }}</div>
        <div class="desc">{{ desc }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "desc"],
};
</script>

<style lang="scss">
.works-card {
  height: 240px;
  background: linear-gradient(124.87deg, #e2e2e2 22.53%, #b6b6b6 93.65%);
  box-shadow: -1px 4px 6px 4px rgba(102, 102, 102, 0.3), inset 0px 0px 8px #e2e2e2;
  border-radius: $mradius;
  border: 0.5px solid #0000003d;
  margin: $mpadding/2 $mpadding;
  padding: $mpadding * 1.5 $mpadding * 1.5 $mpadding * 3 $mpadding * 1.5;
  transition: all 0.5s ease-in-out;
  &:hover {
    // background: linear-gradient(45deg, #b6b6b6 22.53%, #e2e2e2 93.65%);
    transform: scale(1.05) translate3d(0px, -15px, 0);
    transition: transform 0.8s;
    cursor: pointer;
  }
  .icon {
    font-size: 40px;
    margin-bottom: $mpadding * 1.5;
  }
  .title {
    position: relative;
    font-family: $sec_font;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: $mpadding;
    &::before {
      content: "";
      position: absolute;
      top: -$mpadding/2;
      left: 0;
      width: $mpadding;
      border-top: solid 1px;
    }
  }
  .desc {
    font-family: $font_text;
    font-size: 17px;
    line-height: 20px;
    color: #000;
  }
  @media (max-width: $mobile_width) {
    height: 270px;
    padding: $mpadding $mpadding * 1.5 $mpadding * 1.5 $mpadding * 1.5;
  }
}
</style>
