<template>
  <div class="main-banner" v-if="$locale">
    <video
      class="main-banner--video"
      v-if="screen.width > 750"
      :src="getVideo(($route.query.v = 1))"
      autoplay
      loop="loop"
      width="100%"
      muted
      playsinline
    ></video>
    <video
      class="main-banner--video"
      v-if="screen.width < 768"
      :src="getVideo(($route.query.v = 2))"
      autoplay
      loop="loop"
      width="100%"
      muted
      playsinline
    ></video>

    <div class="main">
      <div class="main-banner-content">
        <div class="card">
          <div class="card-content">
            <div class="card-logo">
              <img class="card-logo-figure" :src="$locale['logo-main']" alt="Logo One Group" />
            </div>
            <div class="card-text">
              <p class="title" dtitle bold>{{ $locale["main-banner-title"] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    screen: function () {
      return this.$store.state.screen;
    },
  },
  methods: {
    getVideo: function (video) {
      const videos = {
        1: "https://onegroup.s3.amazonaws.com/website/home/technology-map-2021-08-28-08-06-00-utc.mp4",
        2: "https://onegroup.s3.amazonaws.com/website/home/option3.mp4",
        3: "https://onegroup.s3.amazonaws.com/website/home/worldOnegroup.mp4",
      };
      if (!video) {
        video = Math.floor(Math.random() * (3 - 1) + 1);
      }
      return videos[Number(video)];
    },
  },
};
</script>
<style lang="scss" scoped>
.main-banner {
  .main {
    position: absolute;
  }
  width: 100%;
  position: relative;
  padding-top: $header_height;
  background-color: black;
  background-size: cover;
  &-content {
    position: relative;
    padding: $mpadding $mpadding $mpadding * 3 $mpadding;
    .card {
      position: relative;
      color: #aeaeae;
      border-radius: $mpadding;
      background: linear-gradient(90deg, #00000094 21%, rgba(137, 137, 236, 0) 98%);
      &:before,
      &:after {
        content: "";
        position: absolute;
        right: 0;
        height: 15%;
        border-right: solid 2px #fff;
        width: $mpadding * 2;
        display: none;
      }
      &:before {
        top: 0;
        border-top-right-radius: $mpadding;
      }
      &:after {
        bottom: 0;
        border-bottom-right-radius: $mpadding;
      }
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &-text {
        margin-top: 15px;
        position: relative;
        .title {
          margin: 0 0 $mpadding * 1.5 0;
          font-family: $sec_font;
          color: #fff;
          font-weight: 700;
          font-size: 50px;
          text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.61);
          animation: 1s ease-out 0s 1 animationTitle;
        }
        .subtitle {
          font-family: $first_font;
          font-size: 32px;
        }
      }
      &-logo {
        width: 100vw;
        &-figure {
          width: 100%;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: $tablet_width) {
    .main {
      height: 100%;
      margin-left: 0px;
    }
    &-content {
      height: 100%;
      display: flex;
      align-items: center;
      width: calc(100vw - 30px);
      .card {
        max-width: calc(100vw - 30px);
      }
    }
  }
  @media (min-width: $tablet_width) {
    display: flex;
    justify-content: center;
    align-items: center;
    &-content {
      padding: $mpadding * 3 $mpadding;
      .card {
        border: solid 2px;
        border-right: none;
        padding: $mpadding * 4 $mpadding * 2;
        max-width: calc(100% - 35% / 2);
        &:before,
        &:after {
          display: block;
        }
        &-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
        }
        &-text {
          position: relative;
          width: 80%;
          .title {
            margin: 0 0 $mpadding * 1.5 0;
          }
        }
        &-logo {
          margin: 0 -30% 0 0;
          width: 515px;
        }
      }
    }
  }
  @media (min-width: $desktop_width) {
    &-content {
      .card {
        padding: $mpadding * 5 $mpadding * 2;
        max-width: calc(100% - 30% / 2);
        &-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
        }
        &-text {
          position: relative;
          width: 80%;
          .title {
            font-size: 60px;
          }
        }
        &-logo {
          margin: 0 -193px 0 0;
          width: 518px;
        }
      }
    }
  }
  @media (min-width: $tv_width) {
    &-content {
      .card {
        max-width: 97%;
        padding: 15% 60px;
        margin-right: 140px;
        &-text {
          right: 20%;
          top: -20px;
        }
        &-logo {
          right: -11%;
          top: 11%;
          position: absolute;
          width: 715px;
        }
        &-content {
          top: -1%;
          max-width: 89%;
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .main-banner {
    &-content {
      margin-top: 50px;
    }
    .card {
      &-content {
        margin-top: 30px;
      }
      background: none;
    }
    .card-logo-figure {
      max-width: 50%;
      display: block;
      margin: 0 auto;
    }
    .card-text {
      .title {
        padding-top: 35px;
        position: relative;
        top: 25;
        margin-top: 0px;
        font-size: 30px;
        text-align: center;
      }
    }
    &--video {
      position: absolute;
    }
    .main {
      position: relative;
      height: 250px;
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .main-banner {
    &-content {
      margin-top: 50px;
    }
    .card {
      &-content {
        margin-top: 30px;
      }
      background: none;
    }
    .card-logo-figure {
      max-width: 50%;
      display: block;
      margin: 0 auto;
    }
    .card-text {
      .title {
        padding-top: 50px;
        position: relative;
        margin-top: 10px;
        font-size: 30px;
        text-align: center;
      }
    }
    &--video {
      position: absolute;
    }
    .main {
      position: relative;
      height: 275px;
    }
  }
}
@media only screen and (min-width: 426px) and (max-width: 510px) {
  .main-banner {
    &-content {
      margin-top: 55px;
      padding: 45px 15px 0px 15px;
    }
    .card {
      background: none;
      &-content {
        margin-top: 20px;
        .card-logo {
          margin-top: -60px;
          &-figure {
            max-width: 50%;
            display: block;
            margin: 0 auto;
          }
        }
        .card-text {
          .title {
            position: relative;
            margin-top: 45px;
            font-size: 30px;
            text-align: center;
          }
        }
      }
    }
    &--video {
      position: absolute;
    }
    .main {
      position: relative;
    }
  }
}
@media only screen and (min-width: 511px) and (max-width: 589px) {
  .main-banner {
    &-content {
      margin-top: 50px;
      padding: 65px 15px 0px 15px;
    }
    .card {
      background: none;
      &-content {
        margin-top: 30px;
      }
      &-logo {
        margin-top: -60px;
      }
    }
    .card-logo-figure {
      max-width: 50%;
      display: block;
      margin: 0 auto;
    }
    .card-text {
      .title {
        // padding-top: 40px;
        position: relative;
        margin-top: 50px;
        font-size: 30px;
        text-align: center;
      }
    }
    &--video {
      position: absolute;
    }
    .main {
      position: relative;
    }
  }
}
@media only screen and (min-width: 590px) and (max-width: 767px) {
  .main-banner {
    &-content {
      margin-top: 65px;
      padding: 40px 15px 0px 15px;
    }
    .card {
      background: none;
      &-content {
        margin-top: 60px;
      }
      &-logo {
        margin-top: -60px;
      }
    }
    .card-logo-figure {
      max-width: 50%;
      display: block;
      margin: 0 auto;
    }
    .card-text {
      .title {
        // padding-top: 40px;
        position: relative;
        margin-top: 70px;
        font-size: 30px;
      }
    }
    &--video {
      position: absolute;
    }
    .main {
      position: relative;
    }
  }
}
</style>
